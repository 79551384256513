import React from 'react';
// import db from "../../config/firebase";
import { toast } from "react-toastify";
import moment from 'moment-timezone';
///import {mailUrl} from "../../config/mailFunctions.js";
import { GATEWAY_URL, FUNCTION_API_KEY } from "../../config/siteConfig";
import { getDateInUTC } from "../livechat/components/Comman";
import { activityLog } from "../../services/service";
import firebaseServices from '../../firebase';
const dateFormat = "X";

class SuspendAgent extends React.Component {

    constructor(props) {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {}
        this.state = {
            loader: false,
            ownerId: '',
            agentId: '',
            showSuspendAgent: false,
            agentStatus: '',
            agentStatusMsg: 'Suspend',
            agentEmail: '',
            agentName: '',
            agentAlias: '',
            agentPhoneNumber: ''
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId: userDetails.ownerId
        })
    }

    componentDidUpdate(prev_props) {
        if (this.props.agentId !== this.state.agentId) {
            let message = this.props.agentStatus === 1 ? "Suspend" : 'Activate';
            this.setState({
                agentId: this.props.agentId,
                agentStatus: this.props.agentStatus,
                agentStatusMsg: message,
                agentEmail: this.props.suspendEmail,
                agentName: this.props.suspendAgentName ? this.props.suspendAgentName : '',
                agentAlias: this.props.suspendAgentAlias && this.props.suspendAgentAlias !== '---' ? this.props.suspendAgentAlias : ''
            }, () => {
                if (this.state.agentId !== '' && this.state.agentId !== undefined && this.state.showSuspendAgent === true) {
                    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.props.agentId).get().then((docs) => {
                        this.setState({
                            agentPhoneNumber: docs.data().phone_number
                        })
                    })
                }
            })
            activityLog("Opened suspend agent page");
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showSuspendAgent !== props.sendAgentStatus) {
            return {
                showSuspendAgent: props.sendAgentStatus
            }
        }
        return null
    }

    nameIntials = (nameData) => {
        var arrayData = nameData.split(' ');
        if (arrayData.length === 1) {
            return nameData.substr(0, 2).toUpperCase();
        } else {
            return arrayData[0].charAt(0).toUpperCase() + arrayData[arrayData.length - 1].charAt(0).toUpperCase();
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loader: true
        })
        var agentAlias = this.nameIntials(this.state.agentName);
        var agentName = this.state.agentAlias !== '' ? this.state.agentAlias : this.state.agentName;
        const tmpAgentId = this.state.agentId;
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').where('agent_ids', 'array-contains', this.state.agentId).where('isChatend', '==', 0).get().then(async (data) => {
            if (data.empty === true) {
                let newStatus = 1 - this.state.agentStatus;
                let updDataAgt = { status: newStatus };
                if (newStatus === 0) {
                    updDataAgt.phone_number = "";
                }
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update(updDataAgt).then((ref) => {
                    let toastMsg = newStatus === 1 ? 'activated' : 'suspended';
                    toast.success("Agent has been " + toastMsg + " successfully!");
                    this.sendEmail();
                    this.hideSuspendAgent();
                    activityLog("Agent " + toastMsg);

                    /* To remove phone number if assigned start*/
                    if (newStatus === 0) {
                        this.props.removePhoneNumber(newStatus, tmpAgentId, this.state.agentPhoneNumber);
                    }
                    /* To remove phone number if assigned end */

                    this.setState({
                        loader: false
                    })
                }).catch((err) => {
                    console.log(err);
                })
            } else {
                let serverTimeStamp = await getDateInUTC();
                data.forEach(doc => {
                    var docData = doc.data();
                    var agentArray = docData.agent_ids;
                    if (agentArray.length > 1) {
                        var index = agentArray.indexOf(this.state.agentId);
                        agentArray.splice(index, 1);
                        var agentLeftArray = docData.agent_leave;
                        if (agentLeftArray.indexOf(this.state.agentId) === -1) {
                            agentLeftArray.push(this.state.agentId);
                        }
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).update({
                            updatedon: serverTimeStamp,
                            agent_ids: agentArray,
                            agent_leave: agentLeftArray,
                            latest_msg: agentName + " left the chat"
                        }).then(() => {
                            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).collection('chat_data').doc('LIVE' + serverTimeStamp + Math.ceil(Math.random() * 999999)).set({
                                action: "leavechat",
                                addedon: serverTimeStamp + 500,
                                agent_id: this.state.agentId,
                                agent_name: this.state.agentName,
                                agent_profile_img: "",
                                agent_short: agentAlias,
                                contenttype: "",
                                filesize: 0,
                                msg: agentName + " left the chat",
                                msg_read_status: 1,
                                msg_type: 7,
                                msg_url: "",
                                agent_end: "AGENT_SUSPENDED"
                            })
                        });
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).collection('agent_data').doc(this.state.agentId).update({ status: 2 });
                        // firebaseServices.db.collection('users').doc(this.state.ownerId).collection("visitors").doc(doc.id).collection('typing_status').doc(this.state.agentName).set({ isTyping: false });
                    } else {
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).update({
                            updatedon: serverTimeStamp,
                            agent_end: "AGENT_SUSPENDED",
                            isChatend: 1,
                            visitor_status: 'close',
                            incoming: 3
                        }).then(() => {
                            this.addInHistory(doc.id);
                            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).collection('chat_data').doc('LIVE' + serverTimeStamp + Math.ceil(Math.random() * 999999)).set({
                                action: "EndChat",
                                addedon: serverTimeStamp + 500,
                                agent_id: this.state.agentId,
                                agent_name: this.state.agentName,
                                agent_profile_img: "",
                                agent_short: agentAlias,
                                contenttype: "",
                                filesize: 0,
                                msg: agentName + " end the chat",
                                msg_read_status: 1,
                                msg_type: 7,
                                msg_url: "",
                                agent_end: "AGENT_SUSPENDED"
                            })
                        });
                    }
                })
                let newStatus = 1 - this.state.agentStatus;
                let updDataAgt = { status: newStatus };
                if (newStatus === 0) {
                    updDataAgt.phone_number = "";
                }
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update(updDataAgt).then((ref) => {
                    toast.success("Agent has been suspended successfully!");
                    this.sendEmail();
                    this.hideSuspendAgent();
                    activityLog("Agent suspended");

                    /* To remove phone number if assigned start*/
                    if (newStatus === 0) {
                        this.props.removePhoneNumber(newStatus, tmpAgentId, this.state.agentPhoneNumber);
                    }
                    /* To remove phone number if assigned end */

                    this.setState({
                        loader: false
                    })
                }).catch((err) => {
                    console.log(err);
                    this.setState({
                        loader: true
                    })
                })
            }
        });
    }

    addInHistory = (docId) => {
        //fetch(MAIL_FUNCTION_URL+ "/app/sendchat", {
        fetch(GATEWAY_URL + "/sendchat", {
            method: "post",
            body: JSON.stringify({ ownerId: this.state.ownerId, visitorId: docId }),
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.text())
            .then(response => {
                console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    sendEmail = () => {
        let mailType = this.state.agentStatus === 0 ? 'account_reactivated' : 'account_suspended';
        let ownerEmail = this.agent.ownerEmail ? this.agent.ownerEmail : '';
        var body = [`agentId=${this.state.agentId}&ownerId=${this.state.ownerId}&email=${this.state.agentEmail}&type=${mailType}&name=${this.state.agentName}&ownerEmail=${ownerEmail}`]
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                //   console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    hideSuspendAgent = (e) => {
        this.props.getAgentStatus(false);
    }

    closePanel = (e) => {
        e.preventDefault();
        this.hideSuspendAgent();
    }

    timeZoneFormat = (date) => {
        var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
        todayUTCDate = parseInt(todayUTCDate);
        return todayUTCDate;
    }

    render() {
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <div className="rightPanelSection add-agent" style={{ display: this.state.showSuspendAgent ? 'block' : 'none' }}>
                    <div className="categoryRightPanel">
                        <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                            <div className="right-panel-header k-flex align-items-center justify-content-center">
                                <h4 className="header-title heding-text text-truncate mr-auto white-text">Confirm</h4>
                                <ul className="heder-icon">
                                    <li className="nav-item list-inline-item closeing-r">
                                        <span className="heder-icon-img" onClick={this.closePanel}>
                                            <img alt="" src={require('../../assets/img/icon/right-panel-crose.svg')} />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bodyRightPanel">
                            <div className="rightPanelMain">
                                <div className="agent-form py-5">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-feilds px-5 py-4">
                                            <h5>Do you wish to {this.state.agentStatusMsg} the Agent</h5>
                                            {this.state.agentStatus ? (<p className="card-body-text"><b>Note:</b> All ongoing chats of the agent will get ended once the agent is suspended.</p>) : (null)}
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                <button className="mr-2 btnBlue">Confirm</button>
                                                <button className="btnWhite" onClick={this.closePanel}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SuspendAgent;