import React from 'react';
// import db from "../../config/firebase";
import {toast} from "react-toastify";
import moment from 'moment-timezone';
import {getDateInUTC} from "../livechat/components/Comman";
import {MAIL_FUNCTION_URL,GATEWAY_URL,FUNCTION_API_KEY} from "../../config/siteConfig";
import { activityLog } from "../../services/service";
import firebaseServices from '../../firebase';
const dateFormat="X";

class DeleteAgent extends React.Component {

    constructor(props) {
        super();
        this.Agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {} ;
        this.state = {
            loader:false,
            ownerId:'',
            agentId:'',
            showDeleteAgent:false,
            agentEmail:'',
            agentName:'',
            agentAlias:''
        }
    }

    componentDidMount() {
        let userDetails=JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:userDetails.ownerId
        })
    }

    componentDidUpdate(prev_props) {
        if(this.props.agentId !== this.state.agentId) {
            this.setState({
                agentId:this.props.agentId,
                loader:true
            })
            if(this.props.agentId !== '' && this.state.showDeleteAgent===true) {
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.props.agentId).get().then((docs) => {
                    console.log(docs);
                    if(docs.exists) {
                        this.setState({
                            agentEmail:docs.data().email,
                            agentName:docs.data().name,
                            agentAlias:docs.data().alias,
                            loader:false
                        })
                    } else {
                        this.setState({
                            loader:false
                        })
                    }
                })
            } else {
                this.setState({
                    loader:false
                })
            }
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showDeleteAgent !== props.sendAgentStatus) { 
          return {
            showDeleteAgent: props.sendAgentStatus
          }
        }
        return null
    }

    nameIntials = (nameData) => {
        //console.log('nameData >> ' + nameData);
		var arrayData = nameData.split(' ');
		switch (arrayData.length) {
			case 1:
				return nameData.substr(0, 2).toUpperCase();
				break;
			default:
				return arrayData[0].charAt(0).toUpperCase() + arrayData[arrayData.length - 1].charAt(0).toUpperCase();
		}
	}

    handleSubmit = (e) => {
        e.preventDefault();
        var agentAlias=this.nameIntials(this.state.agentName);
        var agentName = this.state.agentAlias!=='' ? this.state.agentAlias : this.state.agentName;
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').where('agent_ids', 'array-contains', this.state.agentId).where('isChatend','==', 0).get().then(async(data) => {
            if(data.empty===true) {
                this.setState({
                    loader:true
                })
                let deletedon = this.timeZoneFormat(Date.now());
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({deletetime:deletedon}).then((ref) => {
                    this.hideDeleteAgent();
                    this.setState({
                        loader:false
                    })
                    toast.success("Agent has been deleted Successfully");
                    
                    activityLog('Agent deleted');
                }).catch((err) => {
                    console.log(err);
                })
            } else {
                let serverTimeStamp = await getDateInUTC();
                data.forEach(doc => {
                    var docData = doc.data();
                    console.log('docData >>> ', docData);
                    var agentArray=docData.agent_ids;
                    if(agentArray.length > 1) {
                        var index = agentArray.indexOf(this.state.agentId);
                        agentArray.splice(index, 1);
                        var agentLeftArray = docData.agent_leave;
                        if(agentLeftArray.indexOf(this.state.agentId)===-1) {
                            agentLeftArray.push(this.state.agentId);
                        }
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).update({
                            updatedon:serverTimeStamp,
                            agent_ids:agentArray,
                            agent_leave:agentLeftArray,
                            latest_msg:'Agent ' + agentName + " left the chat"
                        }).then(() => {
                            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).collection('chat_data').doc('LIVE' + serverTimeStamp + Math.ceil(Math.random() * 999999)).set({
                                action:"leavechat",
                                addedon:serverTimeStamp+500,
                                agent_id:this.state.agentId,
                                agent_name:this.state.agentName,
                                agent_profile_img:"",
                                agent_short:agentAlias,
                                contenttype:"",
                                filesize:0,
                                msg: 'Agent ' + agentName + " left the chat",
                                msg_read_status:1,
                                msg_type:7,
                                msg_url:"",
                                agent_end:"AGENT_SUSPENDED"
                            })
                        });
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).collection('agent_data').doc(this.state.agentId).update({ status: 2 });
				        // firebaseServices.db.collection('users').doc(this.state.ownerId).collection("visitors").doc(doc.id).collection('typing_status').doc(this.state.agentName).set({ isTyping: false });
                    } else {
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).update({
                            updatedon:serverTimeStamp,
                            agent_end:"AGENT_SUSPENDED",
                            isChatend:1,
                            visitor_status:'close',
                            incoming:3
                        }).then(() => {
                            this.addInHistory(doc.id);
                            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('visitors').doc(doc.id).collection('chat_data').doc('LIVE' + serverTimeStamp + Math.ceil(Math.random() * 999999)).set({
                                action:"EndChat",
                                addedon:serverTimeStamp+500,
                                agent_id:this.state.agentId,
                                agent_name:this.state.agentName,
                                agent_profile_img:"",
                                agent_short:agentAlias,
                                contenttype:"",
                                filesize:0,
                                msg: 'Agent ' + agentName +" end the chat",
                                msg_read_status:1,
                                msg_type:7,
                                msg_url:"",
                                agent_end:"AGENT_SUSPENDED"
                            })
                        });
                    }
                })
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({deletetime:serverTimeStamp}).then((ref) => {
                    this.hideDeleteAgent();
                    this.setState({
                        loader:false
                    })
                    
                    activityLog('Agent deleted');
                    toast.success("Agent has been deleted Successfully");
                }).catch((err) => {
                    console.log(err);
                })
            }
        });
    }

    addInHistory = (docId) => {
        //fetch(MAIL_FUNCTION_URL+ "/app/sendchat", {
        fetch(GATEWAY_URL+ "/sendchat", {
            method: "post",
            body: JSON.stringify({ ownerId: this.state.ownerId, visitorId: docId }),
            headers: {
              "X-API-Key" : FUNCTION_API_KEY,
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Origin": window.location.origin
            }
        }).then(res => res.text())
        .then(response => {
            console.log(response);
        })
        .catch(e => {
            console.log(e, "ERROR");
        });
    }

    hideDeleteAgent = (e) => {
        // this.setState({
        //     showDeleteAgent:false,
        //     agentId:''
        // })
        this.props.getAgentStatus(false);
    }

    closePanel = (e) => {
        e.preventDefault();
        this.hideDeleteAgent();
    }

    timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
    }

    render() {
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="rightPanelSection add-agent" style={{display: this.state.showDeleteAgent ? 'block' : 'none' }}>
                <div className="categoryRightPanel">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Confirm</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.hideDeleteAgent}>
                                        <img alt="" src={ require('../../assets/img/icon/right-panel-crose.svg') } />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel">
                        <div className="rightPanelMain">
                            <div className="agent-form py-5">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4">
                                <h3>Do you wish to delete the Agent</h3>
                                <p><b>Note:</b> All ongoing chats being of the agent will get ended once the agent is deleted.</p>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <button className="mr-2 btnBlue">Delete</button>
                                        <button className="btnWhite" onClick={this.closePanel}>Cancel</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default DeleteAgent;