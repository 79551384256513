import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import Incoming from './incoming';
import ChatContext from '../../components/ChatContext';
// import db from "../../../../config/firebase";
import { ENTER_EMAIL, GATEWAY_URL, FUNCTION_API_KEY } from '../../../../config/siteConfig';
import { getTimeFromDate, validateEmail, checkOngoingChats, nameIntials, notifyInBrowserTab } from '../../components/Comman';
import { setCurrentChat, getCallStatus, rejectCall, endCall } from '../../components/Twilio';
import { deleteChatData, deletePlanData, saveClientPlan } from '../../components/Dblovefield';
import $ from 'jquery';
import { Link } from "react-router-dom";
import { List } from '@material-ui/core';
import ListComponent from './list';
import firebaseServices from '../../../../firebase';
import {log} from "../../../../config/helper";
const NEW_VISITOR_STATUS = "ongoing"; /// Show on Left side
class Ongoing extends PureComponent {
    static contextType = ChatContext;  // this.context will work when it defined as is

    ongoingSnapshot = null;
    chkOngChatinterval = 0;

    // To use global data pass second parameter context in constructor
    constructor(props, context) {
        super(props);
        var offset = new Date().getTimezoneOffset() * 60000;
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.visitorData = [];
        this.closeObj = false;
        this.visitObj = false;
        this.allIdes = [];
        this.multipleCloseIdes = [];
        this.state = {
            visitors: [],
            filteredVisitors: [],
            srchTextVisitor: "",
            clientsToClose: [],
            offset: offset,
            open: true,
            callRender: 0,
            isDisablePanel: false,
            checkReload: false,
            isNewOngoingMessage: false,
            sortChatsBy: "updatedon",
            showUpgradeButton: false,
            closingAll: false,
            confirmReload: false
        };

        checkOngoingChats(this.agent);
        this.chkOngChatinterval = setInterval(() => {
            checkOngoingChats(this.agent);
        }, 120000);
    }

    ////////// Private function start //////////

    componentDidUpdate = () => {
        if (this.context.liveChatDocId.length > 2) {
            let kuchbhi = document.getElementById(this.context.liveChatDocId);
            if (kuchbhi) { kuchbhi.classList.add("selected"); }

        }
        if (this.context.isStartChatClicked !== false) {
            //var newVisitorId = this.context.isStartChatClicked;
            this.context.updateGlobalData("isStartChatClicked", false);
            var selectedArray = this.state.clientsToClose;

            if (selectedArray.length > 0) {
                setTimeout(() => {
                    this.state.filteredVisitors.forEach(visitor => {
                        var vId = visitor.id;
                        if (selectedArray.indexOf(vId) !== -1) {
                            var element = document.getElementById("ps_" + vId);
                            element.classList.add("psOngoing");
                            var element2 = document.getElementById("pcheck_" + vId);
                            element2.classList.add("ongoingCheckbx");
                            document.getElementById("chkbx_" + vId).checked = true;
                        } else {
                            var element4 = document.getElementById("ps_" + vId);
                            element4.classList.remove("psOngoing");
                            var element3 = document.getElementById("pcheck_" + vId);
                            element3.classList.remove("ongoingCheckbx");
                            document.getElementById("chkbx_" + vId).checked = false;
                        }
                    });

                    var checkAnyExistArray = [];
                    selectedArray.forEach(vistId => {
                        if (this.allIdes.indexOf(vistId) !== -1) {
                            checkAnyExistArray.push(vistId);
                        }
                    });

                    if (checkAnyExistArray.length > 0) {
                        document.getElementById("leftCloseMultiChat").style.display = "block";
                        document.getElementById("leftTitleSec").style.display = "none";
                    } else {
                        document.getElementById("leftCloseMultiChat").style.display = "none";
                        document.getElementById("leftTitleSec").style.display = "block";
                    }

                    this.setState({ clientsToClose: checkAnyExistArray });

                }, 600);
            }
        }

        var endChat = this.context.endChatVisitorId ? this.context.endChatVisitorId : "";
        if (endChat !== "" && this.multipleCloseIdes.indexOf(endChat) === -1) {
            this.multipleCloseIdes.push(endChat);
        }

        if (this.context.goneOffline !== this.state.checkReload) {
            if (!this.context.goneOffline && this.state.checkReload) {
                this.multipleCloseIdes = [];
                this.reloadClients();
            }
            this.state.checkReload = this.context.goneOffline;
        }

        /// For twilio Outgoing Start ///////

        if (this.context.callChatId) {
            const copyCallChatId = this.context.callChatId;
            this.context.updateGlobalData("callChatId", null);
            let chkInOngoingInt = setInterval(() => {
                for (var jj = 0; jj < this.state.visitors.length; jj++) {
                    let item = this.state.visitors[jj];
                    if (item.id === copyCallChatId) {
                        clearInterval(chkInOngoingInt);
                        this.centerClientLoading(item);
                        return;
                    }
                }
            }, 1000);
        }

        /// For twilio Outgoing end ///////
        // to get updatebutton status
        setTimeout(() => {
            var showUpgradeButton = this.agent.roleType === 'Owner' && ((this.props.paymentStatus !== 'complete' && this.props.isTrialwithoutCC) || !this.props.currPaymentStatus);
            this.setState({ showUpgradeButton: showUpgradeButton })
        }, 5000);
    }

    /////// Reload Ongoing Visitors After Internet reconnect ///////////

    reloadClients = () => {
        let visitorData = [];
        let clearChatWindow = true;
        let currentVisitor = {};
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("agent_ids", "array-contains", this.agent.agentId).where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('updatedon', 'desc').get().then((visitors) => {
            visitors.forEach((visitor) => {
                var vist = visitor.data();
                if (vist.incoming === 2) {
                    vist.id = visitor.id;
                    /* for count badges */
                    const agent_read_count = vist[this.agent.agentId] ? vist[this.agent.agentId] : "";
                    vist.agent_read_count = (agent_read_count > 0) ? agent_read_count : false;
                    /* for count badges */

                    const idIndex = this.allIdes.indexOf(vist.id);
                    if (idIndex === -1) {
                        this.allIdes.push(vist.id);
                    }

                    const client_company_name = vist.client_company_name ? vist.client_company_name : "";
                    if (client_company_name) {
                        vist.client_short = nameIntials(client_company_name);
                    } else {
                        vist.client_short = nameIntials(vist.client_name);
                    }
                    var client_short_name = vist.client_name;
                    if (client_short_name.length > 20) {
                        client_short_name = client_short_name.substring(0, 20) + '...';
                    }
                    vist.client_short_name = client_short_name;
                    vist.timevalue = getTimeFromDate(vist.updatedon);

                    if (vist.start_url === "incoming_call" || vist.start_url === "outgoing_call") {
                        vist.visitor_type = "call";
                    } else if (vist.start_url === "outgoing_sms" || vist.start_url === "incoming_sms") {
                        vist.visitor_type = "sms";
                    } else if (vist.start_url === "voip_call") {
                        vist.visitor_type = "voip";
                    } else {
                        vist.visitor_type = "chat";
                    }

                    if (vist.id === this.context.liveChatDocId) {
                        clearChatWindow = false;
                        currentVisitor = vist;
                    }

                    visitorData.push(vist);
                }
            });

            this.visitorData = visitorData;
            this.setState({ visitors: visitorData }, () => {
                if (clearChatWindow) {
                    this.clearWindow();
                } else {
                    this.centerClientLoading(currentVisitor, true);
                }
                this.searchClient();
            });
        });
    }

    /* Reload Check Uncheck when new Chat Or message received after selection start */

    reCheckIfSelected = () => {
        const allCheckedIdes = this.state.clientsToClose ? this.state.clientsToClose : [];
        if (allCheckedIdes.length > 0) {
            setTimeout(() => {
                let chkInputs = document.querySelectorAll('.toUseRecheck');
                for (var i = 0; i < chkInputs.length; i++) {
                    chkInputs[i].checked = false;
                    const vID22 = chkInputs[i].value;
                    const element3 = document.getElementById("ps_" + vID22);
                    element3.classList.remove("psOngoing");
                    const element4 = document.getElementById("pcheck_" + vID22);
                    element4.classList.remove("ongoingCheckbx");
                }
                allCheckedIdes.forEach(visitorId => {
                    const element = document.getElementById("ps_" + visitorId);
                    element.classList.add("psOngoing");
                    const element2 = document.getElementById("pcheck_" + visitorId);
                    element2.classList.add("ongoingCheckbx");
                    document.getElementById("chkbx_" + visitorId).checked = true;
                });
            }, 200);
        }
    }

    /* Reload Check Uncheck when new Chat Or message received after selection End */

    checkUncheck = (visitorId) => {

        var selectedArray = this.state.clientsToClose;
        if (selectedArray.indexOf(visitorId) === -1) {
            // if (selectedArray.length >= 5) {
            //     document.getElementById("chkbx_" + visitorId).checked = false;
            //     toast.error('You can close 5 chat at a time!');
            //     return false;
            // } else {
            var element = document.getElementById("ps_" + visitorId);
            element.classList.add("psOngoing");
            var element2 = document.getElementById("pcheck_" + visitorId);
            element2.classList.add("ongoingCheckbx");
            selectedArray.push(visitorId);
            // }
        } else {
            var element3 = document.getElementById("ps_" + visitorId);
            element3.classList.remove("psOngoing");
            var element4 = document.getElementById("pcheck_" + visitorId);
            element4.classList.remove("ongoingCheckbx");
            var index = selectedArray.indexOf(visitorId);
            selectedArray.splice(index, 1);
        }

        if (selectedArray.length > 0) {
            document.getElementById("leftCloseMultiChat").style.display = "block";
            document.getElementById("leftTitleSec").style.display = "none";
        } else {
            document.getElementById("leftCloseMultiChat").style.display = "none";
            document.getElementById("leftTitleSec").style.display = "block";
        }
        this.setState({ clientsToClose: selectedArray, closingAll: false });
    }

    resetMidSection = () => {

        var arrayToSet = [];
        var Obj1 = {
            key: "currentVisitor",
            value: {}
        }

        var Obj2 = {
            key: "midHeadercountry",
            value: "NOT_AVAILABLE"
        }

        var Obj3 = {
            key: "midHeaderbrowser",
            value: ""
        }

        var Obj4 = {
            key: "midHeaderoperating",
            value: ""
        }

        var Obj5 = {
            key: "chatPickStatusTransaction",
            value: { "key": "", "status": "", "Text": "", "msgToShow": null }
        }

        var Obj6 = {
            key: "showMidHeader",
            value: false
        }

        var Obj7 = {
            key: "liveChatDocId",
            value: ""
        }

        var Obj8 = {
            key: "blankChatScreenMsg",
            value: "You've ended an ongoing chat. You can either select another ongoing chat or select an incoming chat"
        };

        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);
        arrayToSet.push(Obj3);
        arrayToSet.push(Obj4);
        arrayToSet.push(Obj5);
        arrayToSet.push(Obj6);
        arrayToSet.push(Obj7);
        arrayToSet.push(Obj8);
        this.context.updateGlobalData("array", arrayToSet);
    }

    closeMultiChats = () => {
        this.setState({ closingAll: true })
        if (this.context.goneOffline) {
            return false;
        }

        try {
            let callData = getCallStatus();
            if (callData.isCallStarted && this.state.clientsToClose.length > 0) {
                if (this.state.clientsToClose.indexOf(callData.visitorId) !== -1) {
                    if (callData.calldirection === "INCOMING") {
                        rejectCall();
                    } else {
                        endCall();
                    }
                }
            }
        } catch (errrrr) { }

        document.getElementById("leftCloseMultiChat").disabled = true;
        let selectedArray = [];
        if (this.multipleCloseIdes.length > 0) {
            var selArray1 = this.multipleCloseIdes ? this.multipleCloseIdes : [];
            selectedArray = selArray1.concat(this.state.clientsToClose);
        } else {
            selectedArray = this.state.clientsToClose;
        }

        this.multipleCloseIdes = selectedArray;
        const totalChats = selectedArray.length;
        if (totalChats > 0) {

            var visitorsAfterClosed = this.state.visitors.filter(data => {
                return selectedArray.indexOf(data.id) === -1 ? true : false;
            });
            this.state.visitors.forEach(data => {
                if (selectedArray.indexOf(data.id) !== -1) {
                    const idIndex = this.allIdes.indexOf(data.id);
                    if (idIndex !== -1) {
                        this.allIdes.splice(idIndex, 1);
                    }
                }
            });
            selectedArray.forEach(selKey => {
                const index = this.visitorData.indexOf(selKey);
                if (index >= 0) {
                    this.visitorData.splice(index, 1);
                }
                try {
                    var element = document.getElementById("ps_" + selKey);
                    element.classList.remove("psOngoing");
                    var element2 = document.getElementById("pcheck_" + selKey);
                    element2.classList.remove("ongoingCheckbx");
                } catch (err) { }
            });

            if (selectedArray.indexOf(this.context.liveChatDocId) !== -1 && this.allIdes.length > 0) {
                const firstKey = this.allIdes[0];
                const index = this.visitorData.findIndex(x => x.id === firstKey);
                if (index !== -1) {
                    this.centerClientLoading(this.visitorData[index]);
                }
            } else if (this.allIdes.length === 0) {
                this.resetMidSection();
            }

            try {
                var ele = document.getElementsByName('ongoingChats');
                for (let i = 0; i < ele.length; i++) {
                    if (ele[i].checked) {
                        ele[i].checked = false;
                    }
                }
            } catch (err) { }

            this.setState({ visitors: visitorsAfterClosed }, () => {
                try {
                    document.getElementById("leftCloseMultiChat").disabled = false;
                    document.getElementById("leftCloseMultiChat").style.display = "none";
                    document.getElementById("leftTitleSec").style.display = "block";
                } catch (err) { }
                this.searchClient();
            });

            /* added by dharmesh */
            var notificationEmails = this.agent.notification_email ? this.agent.notification_email : [];
            const sentiment_analysis = this.agent.sentiment_analysis ? this.agent.sentiment_analysis : false;
            const opinion_analysis = this.agent.opinion_analysis ? this.agent.opinion_analysis : false;
            const index = notificationEmails.indexOf(this.agent.ownerEmail.toLowerCase());
            if (index > -1) {
                notificationEmails.splice(index, 1);
            }

            var body = [`ownerId=${this.agent.ownerId}&agentProfile=${this.agent.profile_img}&agentId=${this.agent.agentId}&agentName=${this.agent.name}&agentShort=${this.agent.agent_short}&visitorsIds=${this.state.clientsToClose}&ownerEmail=${this.agent.ownerEmail.toLowerCase()}&notificationEmails=${JSON.stringify(notificationEmails)}&sentiment_analysis=${sentiment_analysis}&opinion_analysis=${opinion_analysis}`];
            fetch(GATEWAY_URL + "/endMultipleChat", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json())
                .then(response => {
                    const tmpCloseIdes = this.state.clientsToClose;
                    this.setState({ clientsToClose: [], closingAll: false }, () => {
                        try {
                            let chkInputs = document.querySelectorAll('.toUseRecheck');
                            for (var i = 0; i < chkInputs.length; i++) {
                                chkInputs[i].checked = false;
                                const vID22 = chkInputs[i].value;
                                const element3 = document.getElementById("ps_" + vID22);
                                element3.classList.remove("psOngoing");
                                const element4 = document.getElementById("pcheck_" + vID22);
                                element4.classList.remove("ongoingCheckbx");
                            }
                        } catch (errrr) { }
                    });
                    if (selectedArray.indexOf(this.context.liveChatDocId) !== -1) {
                        var arrayToSet = [{ key: "liveChatDocId", value: "" }, { key: "currentVisitor", value: {} }, { key: "chatIdsToClose", value: tmpCloseIdes }];
                        var Obj2 = {
                            key: "midHeadercountry",
                            value: "NOT_AVAILABLE"
                        }
                        var Obj3 = {
                            key: "midHeaderbrowser",
                            value: ""
                        }
                        var Obj4 = {
                            key: "midHeaderoperating",
                            value: ""
                        }
                        var Obj5 = {
                            key: "chatPickStatusTransaction",
                            value: { "key": "", "status": "", "Text": "", "msgToShow": null }
                        }
                        var Obj6 = {
                            key: "showMidHeader",
                            value: false
                        }
                        arrayToSet.push(Obj2);
                        arrayToSet.push(Obj3);
                        arrayToSet.push(Obj4);
                        arrayToSet.push(Obj5);
                        arrayToSet.push(Obj6);
                        this.context.updateGlobalData("array", arrayToSet);
                    } else {
                        this.context.updateGlobalData("chatIdsToClose", tmpCloseIdes);
                    }
                }).catch(err => {
                    console.log(err);
                });
        }
    }
    crossChat = (VisitorId) => {


        this.checkUncheck(VisitorId)
        this.closeMultiChats()
        this.setState({ clientsToClose: [] })
    }


    ////////// Private function End //////////

    componentWillMount = () => {
        /// Update limit from 40 to 500 due to speed issue on 20 Sept 2021
        console.log("runned first")
        this.ongoingSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("agent_ids", "array-contains", this.agent.agentId).where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('updatedon', 'desc').limit(10).onSnapshot((visitors) => {
            console.log(visitors.docs.length,"----------------");
            
            if (this.agent && this.agent.agent_name && this.agent.agentId) {
                log("Line 502", "src/modules/livechat/view/leftSection/incoming.js", "Ongoing", this.agent.agent_name+":"+this.agent.agentId);
            }
            else{
                log("Line 502", "src/modules/livechat/view/leftSection/incoming.js", "Ongoing", "Ongoing chat list updated");
            }
            visitors.docChanges().forEach((visitor) => {
                var vistSnap = visitor.doc.data();
                vistSnap.id = visitor.doc.id;
                const visitor_Id = visitor.doc.id;
                const vincomingval = vistSnap.incoming;

                //console.log("Incoming Type: "+ visitor.type + ", ID : " + visitor_Id + ", visitor_status : " + vistSnap.visitor_status+ ", incoming : " + vincomingval);

                if (this.multipleCloseIdes.length > 0) {
                    if (this.multipleCloseIdes.indexOf(visitor_Id) !== -1) {
                        const idIndex = this.allIdes.indexOf(visitor_Id);
                        if (idIndex !== -1) {
                            this.allIdes.splice(idIndex, 1);
                        }
                        if (this.context.chatPickStatusTransaction.key === visitor_Id && this.context.liveChatDocId === visitor_Id) {
                            this.clearWindow();
                        }
                        return false;
                    }
                }

                /* for count badges */
                const agent_read_count = vistSnap[this.agent.agentId] ? vistSnap[this.agent.agentId] : "";
                vistSnap.agent_read_count = (agent_read_count > 0) ? agent_read_count : false;
                /* for count badges */

                if (vistSnap.start_url === "voip_call" && this.agent.agentId !== vistSnap.agent_start) {
                    /* For Internal Chat start */
                    let agentt = vistSnap.agents ? vistSnap.agents[vistSnap.agent_start] : {};
                    vistSnap.client_name = agentt.name;
                    vistSnap.client_email = agentt.email;
                    vistSnap.client_phone = agentt.phone_number;
                    vistSnap.client_short = agentt.agentShort;
                    /* For Internal Chat end */
                } else {
                    const client_company_name2 = (vistSnap.client_company_name && (vistSnap.client_name === "" || vistSnap.client_name.indexOf("#Visitor") !== -1)) ? vistSnap.client_company_name : "";
                    if (client_company_name2) {
                        vistSnap.client_short = nameIntials(client_company_name2);
                    } else {
                        vistSnap.client_short = nameIntials(vistSnap.client_name);
                    }
                }

                var client_short_name = vistSnap.client_name;
                if (client_short_name.length > 20) {
                    client_short_name = client_short_name.substring(0, 20) + '...';
                }
                vistSnap.client_short_name = client_short_name;
                vistSnap.timevalue = getTimeFromDate(vistSnap.updatedon);

                if (vistSnap.geoDetails.country_name === "Country not detected") {
                    vistSnap.geoDetails.country_name = "N/A";
                }

                if (vistSnap.geoDetails.country_ip === "Not detected") {
                    vistSnap.geoDetails.country_ip = "N/A";
                }

                if (!vistSnap.country) {
                    vistSnap.country = "Country not detected";
                }

                if (!vistSnap.operating) {
                    vistSnap.operating = "OS Default";
                }

                if (!vistSnap.browser) {
                    vistSnap.browser = "Browser Default";
                }

                if (vistSnap.start_url === "incoming_call" || vistSnap.start_url === "outgoing_call") {
                    vistSnap.visitor_type = "call";
                } else if (vistSnap.start_url === "outgoing_sms" || vistSnap.start_url === "incoming_sms" || vistSnap.start_url === "incoming_whatsapp") {
                    vistSnap.visitor_type = "sms";
                } else if (vistSnap.start_url === "voip_call") {
                    vistSnap.visitor_type = "voip";
                } else {
                    vistSnap.visitor_type = "chat";
                }

                if (this.context.chatPickStatusTransaction.key === visitor_Id) {
                    this.visitObj = vistSnap;
                    if (vistSnap.agent_end === "VISITOR_END_CHAT") {
                        this.closeObj = { key: visitor_Id, status: "close", Text: "Close window", msgToShow: vistSnap.message_to_show };
                    }

                    /* if (vistSnap.client_status === "away") {
                        this.closeObj = { key: visitor_Id, status: "close", Text: "End chat", msgToShow: vistSnap.message_to_show };
                    } */

                    if (vistSnap.client_status === "online" && vistSnap.agent_end !== "VISITOR_END_CHAT" && (this.context.chatPickStatusTransaction.status === "close" || this.context.chatPickStatusTransaction.status === "serving")) {
                        this.closeObj = { key: visitor_Id, status: "chatting", Text: "Continue", msgToShow: null };
                    }
                }
                if (visitor.type === "added") {
                    if (vincomingval === 2) {
                        const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                        if (index === -1) {
                            const idIndex = this.allIdes.indexOf(visitor_Id);
                            if (idIndex === -1) {
                                this.allIdes.push(visitor_Id);
                                /* this.setState({ clientsToClose: [] }, () => {
                                    try {
                                        var ele = document.getElementsByName('ongoingChats');
                                        for (let i = 0; i < ele.length; i++) {
                                            if (ele[i].checked) {
                                                ele[i].checked = false;
                                            }
                                        }
                                    } catch (err) {
                                        console.log(err);
                                    }
                                }); */
                            }
                            this.visitorData.splice(0, 0, vistSnap); // Insert the new one 
                            /* For whatsapp data adding start */
                            saveClientPlan(vistSnap);
                            /* For whatsapp data adding start */
                        } else {
                            this.visitorData.splice(index, 1, vistSnap); // replace value of an index
                        }
                    } else if (vincomingval === 3) {
                        const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1);
                        }
                        const idIndex = this.allIdes.indexOf(visitor_Id);
                        if (idIndex !== -1) {
                            this.allIdes.splice(idIndex, 1);
                        }
                    }
                } else if (visitor.type === "modified") {
                    const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                    if (vincomingval === 2) {
                        if (index !== -1) {
                            this.visitorData.splice(index, 1, vistSnap); // replace value of an index
                        } else {
                            this.visitorData.splice(0, 0, vistSnap); // Insert the new one 
                        }
                    } else if (vincomingval === 3 || vistSnap.agent_ids.indexOf(this.agent.agentId) === -1) {
                        if (index !== -1) {
                            this.visitorData.splice(index, 1); // remove from array of an index
                        }
                        const idIndex = this.allIdes.indexOf(visitor_Id);
                        if (idIndex !== -1) {
                            this.allIdes.splice(idIndex, 1);
                        }

                        if (this.context.liveChatDocId === this.context.chatPickStatusTransaction.key && this.context.chatPickStatusTransaction.key === visitor_Id && this.context.liveChatDocId === visitor_Id) {
                            this.clearWindow();
                        }
                    }
                } else if (visitor.type === "removed") {
                    if (vincomingval === 3) {
                        const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.visitorData.splice(index, 1);
                        }
                        const idIndex = this.allIdes.indexOf(visitor_Id);
                        if (idIndex !== -1) {
                            this.allIdes.splice(idIndex, 1);
                        }

                        if (this.context.liveChatDocId === this.context.chatPickStatusTransaction.key && this.context.chatPickStatusTransaction.key === visitor_Id && this.context.liveChatDocId === visitor_Id) {
                            this.clearWindow();
                        }
                    } else {
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(visitor_Id).get().then((doc) => {
                            if (doc.exists) {
                                var _data = doc.data();
                                if (_data.visitor_status === "close" || _data.incoming === 3 || _data.agent_ids.indexOf(this.agent.agentId) === -1) {

                                    if (this.context.liveChatDocId === this.context.chatPickStatusTransaction.key && this.context.chatPickStatusTransaction.key === visitor_Id && this.context.liveChatDocId === visitor_Id) {
                                        this.clearWindow();
                                    }

                                    const index = this.visitorData.findIndex(x => x.id === visitor_Id);
                                    this.visitorData.splice(index, 1); // remove from array of an index
                                    this.setState({ visitors: this.visitorData }, () => {
                                        const idIndex = this.allIdes.indexOf(visitor_Id);
                                        if (idIndex !== -1) {
                                            this.allIdes.splice(idIndex, 1);
                                        }
                                        this.searchClient();
                                    });
                                }
                            }
                        });
                    }
                }
            });

            const tmp_latest_msg = this.visitObj.latest_msg ? this.visitObj.latest_msg : "";

            var shouldReload = false;
            if (this.context.liveChatDocId !== "" && this.context.liveChatDocId === this.visitObj.id && this.visitorData.length > 0 && (tmp_latest_msg.indexOf("transferred chat to agent") !== -1 || (tmp_latest_msg.indexOf("added agent") !== -1 && tmp_latest_msg.indexOf("to the chat") !== -1))) {
                shouldReload = true;
            }

            if (this.closeObj && this.context.liveChatDocId !== "" && this.context.liveChatDocId === this.closeObj.key && this.visitorData.length > 0) {
                var arrayToSet = [{ key: "chatPickStatusTransaction", value: this.closeObj }, { key: "ongoingChatIdes", value: this.allIdes }];
                if (shouldReload) {
                    arrayToSet.push({ key: "reloadCurrentVisitor", value: true });
                    arrayToSet.push({ key: "currentVisitor", value: this.visitObj });
                }
                this.context.updateGlobalData("array", arrayToSet);
            } else if (this.visitObj && this.context.liveChatDocId !== "" && this.context.liveChatDocId === this.visitObj.id && this.visitorData.length > 0) {
                var arrayToSet2 = [{ key: "currentVisitor", value: this.visitObj }, { key: "ongoingChatIdes", value: this.allIdes }];
                if (shouldReload) {
                    arrayToSet2.push({ key: "reloadCurrentVisitor", value: true });
                }
                this.context.updateGlobalData("array", arrayToSet2);
            } else {
                this.context.updateGlobalData("ongoingChatIdes", this.allIdes);
            }

            var tmpVisitorsList;
            if (this.multipleCloseIdes.length > 0) {
                tmpVisitorsList = this.visitorData.filter(data => {
                    return (this.multipleCloseIdes.indexOf(data.id) === -1) ? true : false;
                });
            } else {
                tmpVisitorsList = this.visitorData;
            }

            this.setState({ visitors: tmpVisitorsList }, () => {
                this.searchClient();
                this.checkPhoneCall("reload");
                if (!this.state.closingAll) {
                    this.reCheckIfSelected();
                }
            });
        }, (error) => {
            try {
                this.reloadClients();
                var addedon = Date.now();
                var docId = 'SLOG' + addedon;
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('snapshotLogs').doc(docId).set({
                    addedon: addedon,
                    agent_id: this.agent.agentId,
                    error_from: "ongoing",
                    error: error,
                });
            } catch (err) { }
        });

        /* }); */

        /* For call status when come back from another page */
        this.checkPhoneCall("page_reload");
    }

    clearWindow = () => {
        var arrayToSet = [];
        var Obj1 = {
            key: "currentVisitor",
            value: {}
        }
        var Obj2 = {
            key: "midHeadercountry",
            value: "NOT_AVAILABLE"
        }

        var Obj3 = {
            key: "midHeaderbrowser",
            value: ""
        }

        var Obj4 = {
            key: "midHeaderoperating",
            value: ""
        }

        var Obj5 = {
            key: "chatPickStatusTransaction",
            value: { "key": "", "status": "", "Text": "", "msgToShow": null }
        }

        var Obj6 = {
            key: "showMidHeader",
            value: false
        }

        var Obj7 = {
            key: "liveChatDocId",
            value: ""
        }

        var Obj8 = {
            key: "blankChatScreenMsg",
            value: "Select ongoing, incoming chat or initiate a chat from visual view by clicking on visitor panel."
        };

        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);
        arrayToSet.push(Obj3);
        arrayToSet.push(Obj4);
        arrayToSet.push(Obj5);
        arrayToSet.push(Obj6);
        arrayToSet.push(Obj7);
        arrayToSet.push(Obj8);
        this.context.updateGlobalData("array", arrayToSet);
    }

    ///// Load middle chat if no chat selected after any end/close chat start ////////

    showMidChat = (visitor) => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(visitor.id).get().then(vistors => {
            if (vistors.exists) {
                var vist = vistors.data();
                if (vist.incoming === 2 && vist.visitor_status === "ongoing" && vist.agent_ids.includes(this.agent.agentId)) {
                    let key = vistors.id;
                    vist.id = key;
                    let country = vist.country;
                    let browser = vist.browser;
                    let operating = vist.operating;
                    var arrayToSet = [];
                    if (!validateEmail(vist.client_email)) {
                        vist.client_email = ENTER_EMAIL;
                    }
                    if (!validateEmail(vist.visitor_email)) {
                        vist.visitor_email = ENTER_EMAIL;
                    }

                    var Obj1 = {
                        key: "currentVisitor",
                        value: vist
                    }

                    var Obj2 = {
                        key: "midHeadercountry",
                        value: country
                    }

                    var Obj3 = {
                        key: "midHeaderbrowser",
                        value: browser
                    }

                    var Obj4 = {
                        key: "midHeaderoperating",
                        value: operating
                    }

                    var Obj5;
                    if (vist.agent_end === "VISITOR_END_CHAT") {
                        Obj5 = {
                            key: "chatPickStatusTransaction",
                            value: { key: key, status: "close", Text: "Close window", msgToShow: vist.message_to_show }
                        }
                    } /* else if (visitor.client_status === "away") {
                        Obj5 = {
                            key: "chatPickStatusTransaction",
                            value: { key: key, status: "close", Text: "End chat", msgToShow: visitor.message_to_show }
                        }
                    } */ else {
                        Obj5 = {
                            key: "chatPickStatusTransaction",
                            value: { key: key, status: "chatting", Text: "Continue", msgToShow: null }
                        }
                    }

                    var Obj6 = {
                        key: "showMidHeader",
                        value: true
                    }

                    var Obj7 = {
                        key: "liveChatDocId",
                        value: key
                    }
                    var Obj8 = {
                        key: "showVisitorPaths",
                        value: false
                    }

                    var Obj9 = {
                        key: "rightSectionItemToShow",
                        value: "visitorslist"
                    }

                    var Obj10 = {
                        key: "endChatVisitorId",
                        value: ""
                    }

                    var Obj11 = {
                        key: "isCloseBtnClicked",
                        value: ""
                    }

                    arrayToSet.push(Obj11);
                    arrayToSet.push(Obj1);
                    arrayToSet.push(Obj2);
                    arrayToSet.push(Obj3);
                    arrayToSet.push(Obj4);
                    arrayToSet.push(Obj5);
                    arrayToSet.push(Obj6);
                    arrayToSet.push(Obj7);
                    arrayToSet.push(Obj8);
                    arrayToSet.push(Obj9);
                    arrayToSet.push(Obj10);
                    this.context.updateGlobalData("array", arrayToSet);
                }
            }
        });
    }

    ///// Load middle chat if no chat selected after any end/close chat End ////////

    /////////// Trigger when click on a Visitor at Left Side section in OnGoing ///////

    centerClientLoading = (visitor, reload = false, event = null) => {
        console.log("load_ongoing_clicked")
        if (visitor.id !== this.context.liveChatDocId)
            this.context.updateGlobalData("chatLoader", true)

        console.log("----data----", visitor, this.context.liveChatDocId)
        if (this.context.incoming_guide === false && this.context.incoming_guide_step === 0) {
            this.guidenextStep('incoming', 1, 'incoming_guide_step')
        }

        let key = visitor.id;
        let country = visitor.country;
        let browser = visitor.browser;
        let operating = visitor.operating;
        if (this.context.isMobileDevice) {
            try {
                document.getElementById('SideBarIO').classList.remove('IOLP');
                document.getElementById('new-Main-Chat').classList.remove('VRP');
                document.getElementById('new-Main-Chat').classList.remove('VRP2');
                document.getElementById('respMobiMenu').classList.add('d-none');

            } catch (err) { }
        }

        if (!reload && (this.context.liveChatDocId === key || this.multipleCloseIdes.indexOf(key) !== -1)) {
            return;
        }

        /* For Calling start */

        let shouldCallMethod = true;
        if (event !== null) {
            if (event.target.classList.contains('dontcallvissetmethod')) {
                shouldCallMethod = false;
            }
        }

        if (shouldCallMethod) {
            try {
                setCurrentChat(visitor);
            } catch (errrr) { }
        }

        /* For Calling end */

        // this.setState({ isDisablePanel: true });

        var arrayToSet = [];
        if (!validateEmail(visitor.client_email)) {
            visitor.client_email = ENTER_EMAIL;
        }

        if (!validateEmail(visitor.visitor_email)) {
            visitor.visitor_email = ENTER_EMAIL;
        }

        var Obj1 = {
            key: "currentVisitor",
            value: visitor
        }

        var Obj2 = {
            key: "midHeadercountry",
            value: country
        }

        var Obj3 = {
            key: "midHeaderbrowser",
            value: browser
        }

        var Obj4 = {
            key: "midHeaderoperating",
            value: operating
        }

        var Obj5;
        if (visitor.agent_end === "VISITOR_END_CHAT") {
            Obj5 = {
                key: "chatPickStatusTransaction",
                value: { key: key, status: "close", Text: "Close window", msgToShow: visitor.message_to_show }
            }
        } /* else if (visitor.client_status === "away") {
            Obj5 = {
                key: "chatPickStatusTransaction",
                value: { key: key, status: "close", Text: "End chat", msgToShow: visitor.message_to_show }
            }
        } */ else {
            Obj5 = {
                key: "chatPickStatusTransaction",
                value: { key: key, status: "chatting", Text: "Continue", msgToShow: null }
            }
        }

        var Obj6 = {
            key: "showMidHeader",
            value: true
        }

        var Obj7 = {
            key: "liveChatDocId",
            value: key
        }
        var Obj8 = {
            key: "showVisitorPaths",
            value: false
        }

        var Obj9 = {
            key: "rightSectionItemToShow",
            value: "visitorslist"
        }

        var Obj10 = {
            key: "endChatVisitorId",
            value: ""
        }

        var Obj11 = {
            key: "isCloseBtnClicked",
            value: ""
        }

        var Obj12 = {
            key: "showPastChat",
            value: false
        }
        arrayToSet.push(Obj12);
        arrayToSet.push(Obj11);
        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);
        arrayToSet.push(Obj3);
        arrayToSet.push(Obj4);
        arrayToSet.push(Obj5);
        arrayToSet.push(Obj6);
        arrayToSet.push(Obj7);
        arrayToSet.push(Obj8);
        arrayToSet.push(Obj9);
        arrayToSet.push(Obj10);
        this.context.updateGlobalData("array", arrayToSet);

        // Clearbit Enrichement API is called HERE
        if (this.agent.revealDataEnabled) {
            let enrichmentData = {};

            let mailId = (visitor.visitor_email !== 'noreply@appypie.com' && visitor.visitor_email !== '') ? visitor.visitor_email : visitor.client_email;
            try {
                if (mailId !== 'noreply@appypie.com' && mailId !== '') {
                    firebaseServices.db.collection('enrichmentUsers').doc(mailId).get()
                        .then((doc) => {
                            if (doc.exists) {
                                enrichmentData = doc.data();
                                enrichmentData.visitorId = visitor.id;
                                // console.log("💡🛠 -> file: index.js -> line 278 -> enrichmentData from DB", enrichmentData);
                                this.context.updateGlobalData("enrichmentData", enrichmentData)
                            }
                            else {
                                this.context.updateGlobalData("enrichmentData", "");
                            }
                        })
                        .catch((err) => {
                            console.log('ERROR at clearbit enrichmentData DB: ' + err.message);
                            this.context.updateGlobalData("enrichmentData", "");
                        });
                }
            } catch (err) {
                console.log('ERROR at clearbit enrichmentData: ' + err.message);
                this.context.updateGlobalData("enrichmentData", "");
            }
            // Clearbit Enrichement API is called ENDS HERE
            try {
                firebaseServices.db.collection('premiumUsers').doc(visitor.IPv4).get()
                    .then((docPremium) => {
                        let revealData = "";
                        if (docPremium.exists) {
                            revealData = docPremium.data();
                            revealData.visitorId = visitor.id;
                        }
                        this.context.updateGlobalData("revealData", revealData)
                    }).catch((err) => {
                        console.log('ERROR at clearbit revealData: ' + err.message);
                        this.context.updateGlobalData("revealData", "");
                    });
            } catch (err) {
                console.log('ERROR at clearbit revealData: ' + err.message);
                this.context.updateGlobalData("revealData", "")
            }
            this.context.updateGlobalData("showInfo", false);
        }
        // setTimeout(() => {
        //     this.setState({ isDisablePanel: false });
        // }, 900);

        //// Get Chat Data and add it in chatdetail array ///////	
    }

    /* Sorting functions Start */

    showSortingOptions = () => {
        document.getElementById('leftsorting').style.display = 'flex';
    }
    reloadChats = (confirm) => {

        console.log(confirm, "RELOADCHATS", this.state.filteredVisitors)
        // return
        if (confirm == "yes") {
            this.setState({ confirmReload: false })
            document.getElementById('GlobalLoader').style.display = 'block';
            this.state.filteredVisitors.map(doc => {
                if (doc.isChatend) {
                    if (doc.current_pageUrl === "Whatsapp") {
                        var raw = JSON.stringify({
                            "bId": this.agent.whatsapp && this.agent.whatsapp.whatsappAccessId ? this.agent.whatsapp.whatsappAccessId : "",
                            "VisitorAlias": doc.operating,
                            "visitorId": doc.id,
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: { "Content-Type": "application/json" },
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch("https://us-central1-livechat-production.cloudfunctions.net/whatsapp/end_chat", requestOptions)
                            .then(response => response.text())
                            .then(result => console.log(result))
                            .catch(error => console.log('error', error));

                    }
                    this.sendOngoingtoTrash(doc.client_id)
                }
            })

            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("incoming", "==", 1).get().then((visitors) => {
                visitors.docs.map(doc=>{
                    if(doc.data().isChatend){
                        this.sendIncomingtoTrash(doc.id)
                        deleteChatData(doc.id)
                        deletePlanData(doc.id)

                    }
                })

            })
            setTimeout(() => {
                document.getElementById('GlobalLoader').style.display = 'none';

            }, 500)
        } else if (confirm == "no") {
            this.setState({ confirmReload: false })

        } else {

            this.setState({ confirmReload: true })
        }

    }
    sendIncomingtoTrash=(clientId)=>{
        var change={}
        change["incoming"]=3
        change["status"]=2
        change["visitor_status"]="missed"
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(clientId).update(change).then((doc) => {
        }).catch(err=>{console.error(err.message,"ERRMSG")})
    }
    sendOngoingtoTrash=(clientId)=>{
        var change={}
        change["incoming"]=3
        change["status"]=2
        change["visitor_status"]="closed"
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(clientId).update(change).then((doc) => {
        }).catch(err=>{console.error(err.message,"ERRMSG")})
    }

    closeSorting = () => {
        document.getElementById('leftsorting').style.display = 'none';
    }

    handleSorting = (e) => {
        try {
            if (this.state.clientsToClose.length > 0) {
                let chkInputs = document.querySelectorAll('.toUseRecheck');
                for (var i = 0; i < chkInputs.length; i++) {
                    chkInputs[i].checked = false;
                    const vID22 = chkInputs[i].value;
                    const element3 = document.getElementById("ps_" + vID22);
                    element3.classList.remove("psOngoing");
                    const element4 = document.getElementById("pcheck_" + vID22);
                    element4.classList.remove("ongoingCheckbx");
                }
            }
        } catch (errrr) { }
        try {
            document.getElementById("leftCloseMultiChat").disabled = false;
            document.getElementById("leftCloseMultiChat").style.display = "none";
            document.getElementById("leftTitleSec").style.display = "block";
        } catch (err) { }

        this.setState({ sortChatsBy: e.target.value, clientsToClose: [] }, () => {
            this.searchClient();
        });
    }

    /* Sorting functions End */

    /* search functions Start */

    showsearch = () => {
        document.getElementById('leftsearch').style.display = 'flex';
    }

    closeSearch = () => {
        document.getElementById('searchuser').value = '';
        document.getElementById('leftsearch').style.display = 'none';
        this.setState({ srchTextVisitor: "" }, () => {
            this.searchClient();
        });
    }

    searchClient = () => {
        var searchuser = document.getElementById('searchuser').value;
        var textToSearch = searchuser.toLowerCase().trim();
        var filterVisitors;
        if (textToSearch) {
            filterVisitors = this.state.visitors.filter(data => {
                if (new RegExp(textToSearch, "gi").test(data["client_name"]) || new RegExp(textToSearch, "gi").test(data["client_company_name"])) {
                    return true;
                }
                return false;
            });
        } else {
            filterVisitors = this.state.visitors;
        }
        var selectedArray = this.multipleCloseIdes ? this.multipleCloseIdes : [];
        var tmpvisitorsAfterClosed;
        if (selectedArray.length > 0) {
            tmpvisitorsAfterClosed = filterVisitors.filter(data => {
                return selectedArray.indexOf(data.id) === -1 ? true : false;
            });
        } else {
            tmpvisitorsAfterClosed = filterVisitors;
        }
        let isNewOngoingMessage = false;
        let newOngoingMessageCount = 0;

        let openChatsArr = [];
        let closedChatsArr = [];
        if (tmpvisitorsAfterClosed.length > 0) {
            tmpvisitorsAfterClosed.forEach(vistObj => {
                if (vistObj.isChatend === 1) {
                    closedChatsArr.push(vistObj);
                } else {
                    openChatsArr.push(vistObj);
                }
                if (this.agent.agentId && vistObj.isChatend === 0 && vistObj.hasOwnProperty(this.agent.agentId) && vistObj[this.agent.agentId] > 0) {
                    isNewOngoingMessage = true;
                    newOngoingMessageCount += 1; //parseInt(vistObj[this.agent.agentId]);
                }
            });
        }

        if (newOngoingMessageCount > 0) {
            notifyInBrowserTab(newOngoingMessageCount, this.context.websiteTitle, false);
        } else {
            notifyInBrowserTab(0, this.context.websiteTitle, true);
        }

        if (this.state.sortChatsBy === "openchat") {
            tmpvisitorsAfterClosed.sort(function (a, b) { return a.isChatend - b.isChatend; });
        } else if (this.state.sortChatsBy === "endedchat") {
            tmpvisitorsAfterClosed.sort(function (a, b) { return b.isChatend - a.isChatend; });
        } else {
            //tmpvisitorsAfterClosed.sort(function (a, b) { return b.updatedon - a.updatedon; });
            openChatsArr.sort(function (a, b) { return b.updatedon - a.updatedon; });
            let tmpMergeArray = openChatsArr.concat(closedChatsArr);
            tmpvisitorsAfterClosed = tmpMergeArray;
        }

        this.setState({ srchTextVisitor: textToSearch, filteredVisitors: tmpvisitorsAfterClosed, isNewOngoingMessage: isNewOngoingMessage }, () => {
            //this.context.updateGlobalData("newOngoingMessageCount", newOngoingMessageCount);
            this.setState({ callRender: Date.now() });
        });
    }

    /* search functions End */

    /* accordion function start */

    toggle = () => {
        this.setState({
            open: !this.state.open
        });
    }

    closeHandle = () => {
        this.setState({
            open: false
        });
    }

    /* accordion function End */

    checkPhoneCall = (type) => {
        let callData = getCallStatus();

        if (callData.isCallStarted) {

            var timeoutSeconds = 2000;
            if (type === "reload") {
                $('.hideallcallicons').hide();
                $(".showallhtmlspan").show();
                timeoutSeconds = 10;
            }

            setTimeout(() => {
                if (callData.calldirection === "INCOMING") {
                    if (callData.callstatus === "pending" || callData.isAnswered === false) {
                        try {
                            document.getElementById('geodetail_' + callData.visitorId).style.display = 'none';
                        } catch (eee) { }
                        try {
                            document.getElementById('lastchattime_' + callData.visitorId).style.display = 'none';
                            document.getElementById('call_inprogress_' + callData.visitorId).style.display = 'none';
                            document.getElementById('call_starting_' + callData.visitorId).style.display = 'flex';
                        } catch (errrr) { }
                    } else if (callData.callstatus === "open" || callData.isAnswered === true) {
                        try {
                            document.getElementById('geodetail_' + callData.visitorId).style.display = 'none';
                        } catch (eee) { }
                        try {
                            document.getElementById('lastchattime_' + callData.visitorId).style.display = 'none';
                            document.getElementById('call_starting_' + callData.visitorId).style.display = 'none';
                            document.getElementById('call_inprogress_' + callData.visitorId).style.display = 'flex';
                        } catch (errrr) { }
                    }
                } else if (callData.calldirection === "OUTGOING") {
                    if (callData.callstatus === "open" || callData.callstatus === "ringing" || callData.isAnswered === true) {
                        try {
                            document.getElementById('geodetail_' + callData.visitorId).style.display = 'none';
                        } catch (eee) { }
                        try {
                            document.getElementById('lastchattime_' + callData.visitorId).style.display = 'none';
                            document.getElementById('call_starting_' + callData.visitorId).style.display = 'none';
                            document.getElementById('call_inprogress_' + callData.visitorId).style.display = 'flex';
                        } catch (errrr) { }
                    }
                }
            }, timeoutSeconds);
        }
    }

    closeRightSection = () => {
        this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
    }

    /* Unmount all mounted data start */

    componentWillUnmount = () => {
        try {
            this.ongoingSnapshot();
            clearInterval(this.chkOngChatinterval);
        } catch (errr) { }
    }

    /* Unmount all mounted data end */

    endGuideTour = () => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }
        firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("onboarding_guide").doc("livechat").update({
            status: true
        })
        this.context.updateGlobalData('livechat_guide', true);
    }

    guidenextStep = () => {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if (userDetails === false) {
            return;
        }
        firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("onboarding_guide").doc("livechat").update({
            step: 3
        })
        this.context.updateGlobalData('livechat_step', 3);
    }

    render() {
        console.log(this.context, "THISCONTEXT")
        var disab = "";
        var incHeight = "100vh";
        if (this.state.isDisablePanel || this.context.isDisableIncomingPanel) {
            disab = "none";
        }
        var allSelectedIdes = this.state.clientsToClose ? this.state.clientsToClose : [];
        return (<>
            <div className="k-flex align-item-center position-relative">

                {this.context.livechat_guide === false && this.context.livechat_step === 2 ? (<div className="overlay-guide"></div>) : (null)}

                <h4 className="font-bold mb-3 mr-auto" style={{ "color": "#fff" }}>
                    <span id="leftTitleSec">Live Chat</span>
                    {/* For reset confirmation start */}
                    <div className="popupShadow" style={{ display: this.state.confirmReload ? 'block' : 'none' }}></div>
                    <div className="popupInstallLivechat popupLivechat login-popup" style={{ display: this.state.confirmReload ? 'block' : 'none' }}>
                        <div className="popupHeader py-4 px-5 k-flex align-items-center">
                            <h6 className="card-title mb-0 white-text">Close all end chats</h6>
                            <span className="close-popup ml-auto" onClick={() => { this.reloadChats('no') }}>
                                <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                                    <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                                    <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                                        <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                                        <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                                    </g>
                                </svg>
                            </span>
                        </div>

                        <div className="popupBody pb-5 px-5 text-center">
                            <div className="py-5 feilds-instruction">
                                <h6 className="card-title mb-0">Are you sure you want to close all your end chats?</h6>
                                {/* <h6 className="card-title mb-0">Note: Once you logout all your inactive chats will be removed.</h6> */}
                            </div>
                            <div>
                                <button className="btnBlueSettings  py-2 px-7" onClick={() => { this.reloadChats("yes") }}>Yes</button>&nbsp;
                                <button className="btn  py-2 px-7" onClick={() => { this.reloadChats("no") }}>No</button>
                            </div>
                        </div>
                    </div>
                    {/* For reset confirmation end */}
                    {/* {this.agent.roleType === 'Owner' ? (<Link role="tab" to={"/" + this.agent.ownerIdentifire + "/subscription"}><button className="verifyBtn">Upgrade</button></Link>) : (null)} */}

                    {this.state.showUpgradeButton ? (<button className="upgrade" onClick={this.props.openUpgrade}><span><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 363.276 290.874">
                        <path id="Icon_awesome-crown" data-name="Icon awesome-crown" d="M299.717,254.33H63.6a9.193,9.193,0,0,0-9.193,9.193v18.156a9.193,9.193,0,0,0,9.193,9.193h236.12a9.193,9.193,0,0,0,9.193-9.193V263.523A9.193,9.193,0,0,0,299.717,254.33ZM336.052,72.77A27.257,27.257,0,0,0,308.818,100a26.683,26.683,0,0,0,2.505,11.238L270.185,135.81a18.133,18.133,0,0,1-25.1-6.573l-46.24-80.921a27.234,27.234,0,1,0-34.473,0L118.2,129.237a18.156,18.156,0,0,1-25.1,6.573L52.083,111.2a27.234,27.234,0,1,0-24.8,16.088,27.808,27.808,0,0,0,4.367-.46L72.7,236.243H290.639l41.023-109.511a27.808,27.808,0,0,0,4.367.46,27.245,27.245,0,1,0,0-54.491Z" transform="translate(0.001 0.002)" fill="#ffaf19" />
                    </svg>
                    </span>Upgrade</button>) : (null)}
                    <button className="btnBlue py-2 px-5" disabled={this.state.disableMCBtn === true ? true : false} onClick={() => this.closeMultiChats()} id="leftCloseMultiChat" style={{ display: "none" }}>Close Chat</button>
                </h4>
                <div onClick={() => { this.reloadChats('show') }} className="search-livechat-side cursor-pointer">

                    <span className="search-icon" style={{ marginRight: '7px' }}>
                        {/* <img src={require("../../../../assets/img/icon/sort-descending.svg")} style={{ width: '16px', marginRight: "5px" }} /> */}

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19.945 19.93">
                            <g id="Group_4463" data-name="Group 4463" transform="translate(-16946.695 -2353.686)">
                                <g id="Group_4461" data-name="Group 4461" transform="translate(16946.695 2353.686)">
                                    <g id="Group_4462" data-name="Group 4462" transform="translate(0 0)">
                                        <path id="Path_2779" data-name="Path 2779" d="M0,299.975c.038-.334.081-.668.112-1,.093-.982.176-1.964.272-2.946a.767.767,0,0,1,1.238-.572q1.679,1.182,3.343,2.385a.778.778,0,1,1-.9,1.269c-.172-.116-.338-.24-.507-.361l-.552-.394c.047.09.065.135.091.173a8.15,8.15,0,0,0,6,3.616,8.251,8.251,0,0,0,8.2-4.371.97.97,0,0,1,.448-.492.773.773,0,0,1,.878.167.73.73,0,0,1,.128.9A15.659,15.659,0,0,1,17.6,300.1a9.677,9.677,0,0,1-6.295,3.565c-.249.037-.5.061-.751.09H9.35c-.231-.028-.462-.052-.692-.086a9.762,9.762,0,0,1-6.389-3.652c-.206-.255-.391-.526-.6-.815-.011.07-.021.112-.025.154-.028.31-.053.62-.083.93a.774.774,0,0,1-.9.706A.838.838,0,0,1,0,300.4v-.429" transform="translate(0 -283.824)" fill="#fff" />
                                        <path id="Path_2781" data-name="Path 2781" d="M45.36,6c.037-.393.07-.727.1-1.062a.778.778,0,0,1,.838-.758.77.77,0,0,1,.716.873c-.091,1.054-.192,2.106-.29,3.16-.029.317-.055.634-.089.95a.771.771,0,0,1-1.248.557c-1.007-.711-2.008-1.431-3.011-2.147-.111-.079-.224-.156-.332-.239a.778.778,0,1,1,.9-1.266c.342.234.677.48,1.041.706a1.487,1.487,0,0,0-.074-.13,7.977,7.977,0,0,0-6.184-3.611A8.085,8.085,0,0,0,29.722,7.4a1.02,1.02,0,0,1-.466.5.773.773,0,0,1-.861-.171.765.765,0,0,1-.136-.9,9.436,9.436,0,0,1,1.306-1.953,9.449,9.449,0,0,1,6.163-3.356A9.63,9.63,0,0,1,45.218,5.8l.142.2" transform="translate(-27.071 -1.44)" fill="#fff" />
                                    </g>
                                </g>
                            </g>
                        </svg>


                        {/* <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 492.883 492.883" style="margin-top: 10px;"><g><g><path d="M122.941,374.241c-20.1-18.1-34.6-39.8-44.1-63.1c-25.2-61.8-13.4-135.3,35.8-186l45.4,45.4c2.5,2.5,7,0.7,7.6-3 l24.8-162.3c0.4-2.7-1.9-5-4.6-4.6l-162.4,24.8c-3.7,0.6-5.5,5.1-3,7.6l45.5,45.5c-75.1,76.8-87.9,192-38.6,282 c14.8,27.1,35.3,51.9,61.4,72.7c44.4,35.3,99,52.2,153.2,51.1l10.2-66.7C207.441,421.641,159.441,407.241,122.941,374.241z"></path><path d="M424.941,414.341c75.1-76.8,87.9-192,38.6-282c-14.8-27.1-35.3-51.9-61.4-72.7c-44.4-35.3-99-52.2-153.2-51.1l-10.2,66.7 c46.6-4,94.7,10.4,131.2,43.4c20.1,18.1,34.6,39.8,44.1,63.1c25.2,61.8,13.4,135.3-35.8,186l-45.4-45.4c-2.5-2.5-7-0.7-7.6,3 l-24.8,162.3c-0.4,2.7,1.9,5,4.6,4.6l162.4-24.8c3.7-0.6,5.4-5.1,3-7.6L424.941,414.341z"></path></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg> */}

                    </span>
                </div>
                <div onClick={this.showSortingOptions} className="search-livechat-side cursor-pointer">
                    <span className="search-icon">
                        <img src={require("../../../../assets/img/icon/sort-descending.svg")} style={{ width: '16px', marginRight: "5px" }} />
                    </span>
                </div>
                <div onClick={this.showsearch} className="search-livechat-side cursor-pointer">
                    <span className="search-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356"
                            viewBox="0 0 20.618 21.356">
                            <path id="search"
                                d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z"
                                transform="translate(-0.984)" fill="#fff" />
                        </svg>
                    </span>
                </div>
                <div className="input-group visitore-search on-in-chats" id="leftsorting" style={{ display: "none" }} >
                    <select className="form-control drop-ongoing" name="sortChatsBy" id="sortChatsBy" value={this.state.sortChatsBy} onChange={this.handleSorting}>
                        <option value="updatedon">Latest Update</option>
                        <option value="openchat">Open Chats First</option>
                        <option value="endedchat">Ended Chats First</option>
                    </select>
                    <div className="input-group-append">
                        <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit" onClick={this.closeSorting}>
                            <svg id="signs" xmlns="http://www.w3.org/2000/svg" width="49.468" height="49.468" viewBox="0 0 49.468 49.468">
                                <path id="Path_2867" data-name="Path 2867" d="M24.734,49.468A24.734,24.734,0,1,1,49.468,24.734,24.762,24.762,0,0,1,24.734,49.468Zm0-45.934a21.2,21.2,0,1,0,21.2,21.2A21.225,21.225,0,0,0,24.734,3.533Zm0,0" />
                                <path id="Path_2868" data-name="Path 2868" d="M165.72,167.488a1.76,1.76,0,0,1-1.249-.518L146.8,149.3a1.766,1.766,0,0,1,2.5-2.5l17.667,17.667a1.767,1.767,0,0,1-1.249,3.016Zm0,0" transform="translate(-132.152 -132.154)" />
                                <path id="Path_2869" data-name="Path 2869" d="M148.053,167.487a1.767,1.767,0,0,1-1.249-3.016L164.471,146.8a1.766,1.766,0,0,1,2.5,2.5L149.3,166.969A1.76,1.76,0,0,1,148.053,167.487Zm0,0" transform="translate(-132.152 -132.152)" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="input-group visitore-search on-in-chats" id="leftsearch" style={{ display: "none" }} >
                    <input type="text" className="form-control form-control-lg" placeholder="Search here" id="searchuser" onKeyUp={this.searchClient} />
                    <div className="input-group-append">
                        <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit" onClick={this.closeSearch}>
                            <svg id="signs" xmlns="http://www.w3.org/2000/svg" width="49.468" height="49.468" viewBox="0 0 49.468 49.468">
                                <path id="Path_2867" data-name="Path 2867" d="M24.734,49.468A24.734,24.734,0,1,1,49.468,24.734,24.762,24.762,0,0,1,24.734,49.468Zm0-45.934a21.2,21.2,0,1,0,21.2,21.2A21.225,21.225,0,0,0,24.734,3.533Zm0,0" />
                                <path id="Path_2868" data-name="Path 2868" d="M165.72,167.488a1.76,1.76,0,0,1-1.249-.518L146.8,149.3a1.766,1.766,0,0,1,2.5-2.5l17.667,17.667a1.767,1.767,0,0,1-1.249,3.016Zm0,0" transform="translate(-132.152 -132.154)" />
                                <path id="Path_2869" data-name="Path 2869" d="M148.053,167.487a1.767,1.767,0,0,1-1.249-3.016L164.471,146.8a1.766,1.766,0,0,1,2.5,2.5L149.3,166.969A1.76,1.76,0,0,1,148.053,167.487Zm0,0" transform="translate(-132.152 -132.152)" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className={this.context.livechat_guide === false && this.context.livechat_step === 2 ? "ongoingSection position-top" : "ongoingSection"}>
                <div className="ongoingng-user left-panel-user mb-2">
                    <button onClick={this.toggle.bind(this)} className="btn btn-lg btn-block d-flex align-items-center incoming-outgoing-btn" type="button" style={{ background: this.state.isNewOngoingMessage ? '#e87b2e' : '' }}>
                        <span className="mr-4">
                            {this.state.open ? (<img alt="" src={require('../../../../assets/images/livechat/down.svg')} />) : (<img alt="" src={require('../../../../assets/images/livechat/left.svg')} />)}
                        </span>
                        Ongoing Conversation
                        <span className="ml-auto">{this.state.filteredVisitors.length}</span>
                    </button>
                    {this.context.livechat_guide === false && this.context.livechat_step === 2 ? (<div className="tour_guide">
                        <p className="title">Step 3 of 4</p>
                        <p className="text-center">Under this all your ongoing conversations will appear.</p>
                        <button className="btn-next" onClick={this.guidenextStep}>Next</button><button className="end-btn" onClick={this.endGuideTour}>End tour</button></div>) : (null)}
                </div>
                {this.state.open ? <nav style={{ pointerEvents: disab, height: "100vh" }} id="ongoing_collapse" className={"nav d-block list-discussions-js mb-n6 collapse scroll-2" + (this.state.open ? ' in' : '')}>
                    <ListComponent
                        items={this.state.filteredVisitors}
                        centerClientLoading={this.centerClientLoading}
                        visitor_status={NEW_VISITOR_STATUS}
                        allSelectedIdes={allSelectedIdes}
                        open={this.state.open}
                        closeRightSection={this.closeRightSection}

                    />

                </nav> : null}
            </div>

            <Incoming srchTextVisitor={this.state.srchTextVisitor} isOpen={this.state.open} closeHandle={this.closeHandle} />
        </>);
    }
}

export default React.memo(Ongoing);