import React from 'react';
import LeftPanel from "./views/LeftPanel";
// import db from "../../config/firebase";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip"; 
import { activityLog } from '../../services/service';
import firebaseServices from '../../firebase';
import { log } from '../../config/helper';
const dateFormat="X";

class PreviousChat extends React.Component {
    constructor () {
        super();
        this.state = {
            loader:false,
            show_previous_chat:'',
            ownerId:''
        }
    }

    componentDidMount() {
        this.setState({
            loader:true
        })
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:userDetails.ownerId
        }, () => {
            this.getValue();
        })
        activityLog('Opened previous chat');
    }

    getValue = () => {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        var defSettings = firebaseServices.db.collection('users').doc(this.state.ownerId).collection("settings").doc('DEFAULTSETTINGS').onSnapshot((docs) => {
            if (userDetails && userDetails.agent_name && userDetails.agentId) {
                log("Line 38", "/Users/kd/Documents/sprint/LiveChat/src/modules/settings/PreviousChat.js", "PreviousChat", userDetails.agent_name+":"+userDetails.agentId);
            }
            else{
                log("Line 38", "/Users/kd/Documents/sprint/LiveChat/src/modules/settings/PreviousChat.js", "PreviousChat", "PreviousChat chat list updated");
            }
            if(docs.exists) {
                let data =docs.data();
                this.setState({
                    show_previous_chat:data.show_previous_chat,
                    loader:false
                })
                defSettings();
            }
        })
    }

    handleChange = (e) => {
        let target = e.target;
        this.setState({
            [target.name]:JSON.parse(target.value)
        })
    }

    saveForm = () => {
        this.setState({
            loader:true
        })
        let updatedon = this.timeZoneFormat(Date.now());
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection("settings").doc('DEFAULTSETTINGS').update({updatedon:updatedon,show_previous_chat:JSON.parse(this.state.show_previous_chat)}).then((ref) => {
            this.setState({
                loader:false
            }, () => {
                toast.success("Previous Chat Settings has been updated!");
                activityLog('Saved previous chat');
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.saveForm();
    }

    timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
    }

    discardChanges = (e) => {
        e.preventDefault();
        this.getValue();
    }


    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render() {
        return(
            <>
            <ReactTooltip />
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <LeftPanel />
            
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                <h3 className="heding-text">Settings</h3>
                <div className="text-center k-flex align-items-center ml-auto">
                    <button className="btnBlueSettings mr-2 py-2 px-6 d-none" onClick={this.saveForm}>Save changes</button>
                    <button className="btnBorderSettings mr-2 py-2 px-4 d-none" onClick={this.discardChanges}>Cancel</button>
                </div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="chatform-Preview">
                        <div className="container-fluid">
                        <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-4">
                                    <div className="bg-white left-widgetsetings-box">
                                        <div className="setting-section pb-1">
                                            <div className="mx-8">
                                                <div className="main-heading main-heading-title py-5">
                                                    <h3 className="heding-text">Assign chat via</h3>
                                                </div>
                                            </div>
                                        
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="settings-form-feilds mb-8 mx-8">
                                                <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                    <span className="main-checkbox-all">
                                                        <label className="containerRadio containerafterchecked">
                                                            <input type="radio" value="true" onChange={this.handleChange} checked  name="show_round_robin" />
                                                            <span className="radioCheckmark"></span>
                                                        </label>
                                                    </span>
                                                    <h6 className="card-title mb-0 pr-3">Round Robin</h6>
                                                    <span className="info-icon">
                                                    <img src={ require('../../assets/img/icon/info.png') } data-tip="Enabling this will ensure that each agent gets chat assigned through round robin with least chat handling agent getting the chat first" alt="Enabling this will ensure that each agent gets chat assigned through round robin with least chat handling agent getting the chat first" />
                                                    </span>
                                                </div>
                                                <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                    <span className="main-checkbox-all">
                                                        <label className="containerRadio containerafterchecked">
                                                            <input type="radio" value="false" onChange={this.handleChange}  name="show_round_robin" />
                                                            <span className="radioCheckmark"></span>
                                                        </label>
                                                    </span>
                                                    <h6 className="card-title mb-0 pr-3">Manual</h6>
                                                    <span className="info-icon">
                                                    <img src={ require('../../assets/img/icon/info.png') } data-tip="Enabling this will grant agents visibility into all incoming chats, allowing them to select chats manually."  alt="Enabling this will grant agents visibility into all incoming chats, allowing them to select chats manually." />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="chat-feilds py-6 px-8 btn-chat-feilds setting-footer-fixed">
                                                <div className="text-center k-flex align-items-center">
                                                    <button className="btnBlueSettings mr-2">Save changes</button>
                                                    <button className="btnBorderSettings" onClick={this.discardChanges}>Cancel</button>
                                                </div>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-5 mb-5">
                                    <div className="bg-white left-widgetsetings-box">
                                        <div className="setting-section pb-1">
                                            <div className="mx-8">
                                                <div className="main-heading main-heading-title py-5">
                                                    <h3 className="heding-text">Previous chats</h3>
                                                </div>
                                            </div>
                                        
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="settings-form-feilds mb-8 mx-8">
                                                <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                    <span className="main-checkbox-all">
                                                        <label className="containerRadio containerafterchecked">
                                                            <input type="radio" value="true" onChange={this.handleChange} checked={this.state.show_previous_chat===true ? true : false}  name="show_previous_chat" />
                                                            <span className="radioCheckmark"></span>
                                                        </label>
                                                    </span>
                                                    <h6 className="card-title mb-0 pr-3">Show previous chats</h6>
                                                    <span className="info-icon">
                                                    <img src={ require('../../assets/img/icon/info.png') } data-tip="On selecting this option the visitor will be able to see his previous chats." alt="On selecting this option the visitor will be able to see his previous chats." />
                                                    </span>
                                                </div>
                                                <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                    <span className="main-checkbox-all">
                                                        <label className="containerRadio containerafterchecked">
                                                            <input type="radio" value="false" onChange={this.handleChange} checked={this.state.show_previous_chat===false ? true : false} name="show_previous_chat" />
                                                            <span className="radioCheckmark"></span>
                                                        </label>
                                                    </span>
                                                    <h6 className="card-title mb-0 pr-3">Hide previous chats</h6>
                                                    <span className="info-icon">
                                                    <img src={ require('../../assets/img/icon/info.png') } data-tip="On selecting this option the visitor will not be able to see his previous chats."  alt="On selecting this option the visitor will not be able to see his previous chats." />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="chat-feilds py-6 px-8 btn-chat-feilds setting-footer-fixed">
                                                <div className="text-center k-flex align-items-center">
                                                    <button className="btnBlueSettings mr-2">Save changes</button>
                                                    <button className="btnBorderSettings" onClick={this.discardChanges}>Cancel</button>
                                                </div>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default PreviousChat;